img {
    max-width: 100%;
    height: auto;
    max-height: 100%;
}

body {
    font-size: 1rem;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.4rem;
    letter-spacing: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
    padding: 0;
}

html {
    margin: 0;
    padding: 0;
}

* {
    box-sizing: border-box;
}

input {
    font-size: initial; // prevents iOS and Android from zooming the page content when a keyboard is displayed
}
