/*
    LAYOUT
*/
$sidebarWidthDesktop: 250px;
$contentMaxWidthDesktop: 800px;
$gutter: 20px;

/*
    GLOBAL
*/
$defaultTransition: 0.2s;

/*
    COLORS
*/
$white: #ffffff;
$grayPale: #e2eaee;
$primary: #003da1;
$primaryDarker: darken(#003da1, 10%);
$secondary: #52cc7a;
$secondaryDarker: darken(#52cc7a, 2%);
$violetRed: #aa005f;
$warning: #ed2c3e;

$revelBlue: #26225d;
$lightblue: #4fc4d6;

$adminPrimary: $revelBlue;
$adminGray: #616465;

/*
    TYPOGRAPHY
*/
$textColorNormal: #3e4043 !default;
$textColorInverse: #efefef !default;
$fontFamily: Montserrat, sans-serif !default;

/*
    VISUALS
*/
$boxShadowLight: -1px 1.7px 8px 0 rgba(1, 1, 1, 0.1);

/*
    MARGINS
*/
$layoutTopMargin: 40px;
