@import 'variables';
@import 'breakpoints';

@mixin mobile {
    @media screen and (min-width: #{$phoneWidth}) and (max-width: #{$tabletWidth}) {
        @content;
    }
}

@mixin desktop {
    @media screen and (min-width: #{$tabletWidth + 1}) {
        @content;
    }
}

@mixin desktopLarge {
    @media screen and (min-width: #{$desktopLargeWidth + 1}) {
        @content;
    }
}

@function buildString($slug, $property, $default) {
    @return var(#{$slug}-#{$property}, #{$default});
}

$properties: 'color', 'font-size', 'font-weight', 'font-family', 'letter-spacing', 'line-height', 'opacity';

$globalDefaults: (
    color: '#333',
    font-family: $fontFamily,
    font-size: 32px,
    font-weight: 400,
    font-spacing: 0,
    line-height: 1.2,
    opacity: 1
);

@mixin typographyObject($slug, $defaults: $globalDefaults) {
    @each $property in $properties {
        #{$property}: #{buildString($slug, $property, map_get($defaults, #{$property}))};
    }

    @include desktopLarge() {
        @each $property in $properties {
            $fallback: #{buildString($slug, $property, map_get($defaults, $property))};
            #{$property}: #{buildString($slug + '-desktop-large', $property, $fallback)};
        }
    }

    @include desktop() {
        @each $property in $properties {
            $fallback: #{buildString($slug, $property, map_get($defaults, $property))};
            #{$property}: #{buildString($slug + '-desktop', $property, $fallback)};
        }
    }

    @include mobile() {
        @each $property in $properties {
            $fallback: #{buildString($slug, $property, map_get($defaults, $property))};
            #{$property}: #{buildString($slug + '-mobile', $property, $fallback)};
        }
    }
}

@mixin renderCssCustomProps($slug, $defaults) {
    @each $key, $value in $defaults {
        #{$key}: #{buildString($slug, $key, map_get($defaults, #{$key}))};
    }

    @include desktopLarge() {
        @each $key, $value in $defaults {
            $fallback: #{buildString($slug, $key, map_get($defaults, $key))};
            #{$key}: #{buildString($slug + '-desktop-large', $key, $fallback)};
        }
    }

    @include desktop() {
        @each $key, $value in $defaults {
            $fallback: #{buildString($slug, $key, map_get($defaults, $key))};
            #{$key}: #{buildString($slug + '-desktop', $key, $fallback)};
        }
    }

    @include mobile() {
        @each $key, $value in $defaults {
            $fallback: #{buildString($slug, $key, map_get($defaults, $key))};
            #{$key}: #{buildString($slug + '-mobile', $key, $fallback)};
        }
    }
}
@mixin mat-button-theme($theme) {
    $primary: map-get($theme-primary, primary);
    $accent: map-get($theme-accent, accent);
    $warn: map-get($theme-error, warn);

    // .mat-button-base {
    //     &.mat-primary {
    //         color: mat-color($primary);
    //         background-color: mat-color($primary);
    //     }

    //     &.mat-accent {
    //         color: mat-color($accent);
    //         background-color: mat-color($accent);
    //     }

    //     &.mat-warn {
    //         color: mat-color($warn);
    //         background-color: mat-color($warn);
    //     }
    // }
}
