h1 {
    font-size: 2rem;
    line-height: 2.2rem;
    color: primary(500);
}

h2 {
    font-size: 1.8rem;
    line-height: 2rem;
}

h3 {
    font-size: 1.2rem;
    line-height: 1.4rem;
}

h4 {
    font-size: 1.1rem;
    line-height: 1.3rem;
}
