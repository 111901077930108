@import 'helpers';
button {
    &.revel-flat-button {
        .mat-button-wrapper {
            color: revel-blue(500);
        }
        &:focus {
            background-color: revel-blue(100);
        }
        &:hover {
            background-color: revel-blue(50);
            color: revel-blue(900);
        }
    }

    &.revel-raised-button {
        &:focus {
            background-color: revel-blue(700);
        }
        &:hover {
            background-color: revel-blue(900);
        }
    }

    &.revel-stroked-button {
        &:focus {
            background-color: revel-blue(700);
        }
        &:hover {
            background-color: revel-blue(900);
        }
    }
}
