@import 'mixins';

html,
body {
    height: 100%;
}

//TODO Move up to app.

.content {
    background: #ffffff;
}

.dialogPanel {
    mat-dialog-container {
        border-radius: 0px;
        border-top: 4px solid #58ce8f;
    }
}

.error-box {
    display: flex;
    flex-flow: row nowrap;
    margin: 0 30px;
    .meta {
        max-width: 550px;
        .actions {
            max-width: 200px;
            margin-top: 40px;
        }
    }
    @include mobile() {
        flex-flow: column nowrap;
        .image {
            img {
                margin: 0 auto;
            }
        }
        .meta {
            h1,
            h2 {
                text-align: center;
            }
            .actions {
                margin: 40px auto 0 auto;
            }
        }
    }
    .image {
        margin-right: 30px;
        flex-shrink: 0;
        img {
            width: 205px;
            display: block;
        }
    }
}

mat-hint {
    &.mat-hint {
        color: #58595b;
    }
}

.revel-raised-button {
    font-family: 'Montserrat', sans-serif !important;
    background-color: #3c6fb6 !important;
    font-weight: 700 !important;
}

.revel-stroked-button {
    font-family: 'Montserrat', sans-serif !important;
    background-color: #3c6fb6 !important;
    font-weight: 700 !important;
    border: 1px solid;
    color: #fff !important;
    height: 70px !important;
}
