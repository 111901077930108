$text-font-family: Montserrat, sans-serif;

$fontConfig: (
    font-family: $text-font-family,
    display-4: mat-typography-level(112px, 112px, 300, $letter-spacing: -0.0134em),
    display-3: mat-typography-level(56px, 56px, 400, $letter-spacing: -0.0089em),
    display-2: mat-typography-level(45px, 48px, 400, $letter-spacing: 0em),
    display-1: mat-typography-level(34px, 40px, 400, $letter-spacing: 0.0074em),
    headline: mat-typography-level(24px, 32px, 400, $letter-spacing: 0em),
    title: mat-typography-level(20px, 32px, 500, $letter-spacing: 0.0075em),
    subheading-2: mat-typography-level(16px, 28px, 400, $letter-spacing: 0.0094em),
    subheading-1: mat-typography-level(15px, 24px, 500, $letter-spacing: 0.0067em),
    body-2: mat-typography-level(14px, 24px, 500, $letter-spacing: 0.0179em),
    body-1: mat-typography-level(14px, 20px, 400, $letter-spacing: 0.0179em),
    button: mat-typography-level(16px, 20px, 700, $letter-spacing: 0.0893em),
    caption: mat-typography-level(12px, 20px, 400, $letter-spacing: 0.0333em),
    input: mat-typography-level(14px, 1, 500)
);
