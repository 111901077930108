@import '../helpers';

body {
    font-family: $text-font-family;

    --primary-color: #{mat-color($theme-primary, 500)};
    --primary-lighter-color: #{mat-color($theme-primary, 100)};
    --primary-darker-color: #{mat-color($theme-primary, 800)};
    --text-primary-color: #{$light-primary-text};
    --text-primary-lighter-color: #{$dark-primary-text};
    --text-primary-darker-color: #{$light-primary-text};

    --accent-color: #{mat-color($theme-accent, 500)};
    --accent-lighter-color: #{mat-color($theme-accent, 100)};
    --accent-darker-color: #{mat-color($theme-accent, 800)};
    --text-accent-color: #{$light-primary-text};
    --text-accent-lighter-color: #{$dark-primary-text};
    --text-accent-darker-color: #{$light-primary-text};

    --warn-color: #{mat-color($theme-error, 500)};
    --warn-lighter-color: #{mat-color($theme-error, 100)};
    --warn-darker-color: #{mat-color($theme-error, 800)};
    --text-warn-color: #{$dark-primary-text};
    --text-warn-lighter-color: #{$dark-primary-text};
    --text-warn-darker-color: #{$light-primary-text};
}
